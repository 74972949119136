<template lang="pug">
div(v-if="Object.keys(documentEducation).length").bg-white.p-0
  div.flex-row-sb.justify-between.w-100.p-0.pb-5
    div.text-left.text-uppercase.p-0
      | {{ $t(`document-nostrification-${(Object.keys(documentEducation?.behavior)[0])}`, { id: documentEducation?.id }) }}
    div.documentIconControl
      ControlButtons(:controlButtons="controlButtons")
  Info(v-if="documentEducation?.behavior?.viewInfoBlock" :documentEducation="documentEducation")
  ViewPhotoList(
    v-else-if="documentEducation?.behavior?.viewFilesBlock"
    :sailorDocument="documentEducation"
    documentType="nostrification"
  )
</template>

<script>
import { mapActions } from 'vuex'
import { viewDetailedComponent, addNeedFieldForViewFiles } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
export default {
  components: {
    Info: () => import('./Info.vue'),
    ViewPhotoList: () => import('@/components/atoms/ViewPhotoList.vue'),
    ControlButtons: () => import('@/components/atoms/ControlButtons.vue')
  },
  props: {
    documentId: { type: Number, default: null },
    files: { type: Array, default: () => [] }
  },
  data () {
    return {
      documentEducation: {},
      controlButtons: [
        {
          id: 3,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.documentEducation, 'viewInfoBlock'),
          checkAccess: () => checkAccess('nostrification', 'view'),
          color: '#42627e',
          tooltip: 'documentIconControl.info'
        },
        {
          id: 6,
          name: 'mdi-image-outline',
          action: () => viewDetailedComponent(this.documentEducation, 'viewFilesBlock'),
          checkAccess: () => checkAccess('nostrification', 'view_file'),
          color: '#42627e',
          tooltip: 'documentIconControl.viewfiles'
        }
      ]
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    ...mapActions([
      'getDocumentNostrificationById',
      'updateDocumentNostrificationById',
      'getTranslatorCompanies'
    ]),
    async getData () {
      const response = await this.getDocumentNostrificationById({ documentId: this.documentId }) || {}
      response.files = await addNeedFieldForViewFiles(response.files)
      this.documentEducation = response
      await this.getTranslatorCompanies({ language: this.documentEducation.language })
    }
  }
}
</script>
